$font-family-sans-serif: 'Open Sans', sans-serif !default;

$font-size-html-base: 15px !default;
$font-size-html-sm: $font-size-html-base !default;
$font-size-html-md: $font-size-html-sm !default;
$font-size-html-lg: $font-size-html-md !default;

$body-bg: #fdfdfd !default;
$body-color: #222 !default;

$blue: #458ecc !default;
$red: #d14d38 !default;
$yellow: #faaf3e !default;
$green: #76c279 !default;
$cyan: #5bc0de !default;

$default-margin: 15px !default;

$default-footer-background: #eee !default;
$default-footer-font-color: $body-color !default;
$default-footer-link-color: $blue !default;
$default-footer-link-color-hover: lighten($default-footer-link-color, 10%) !default;

//Vendor Styles
$fa-font-path: '../fonts/font-awesome/fonts' !default;

$slick-arrow-color: #111 !default;
$slick-font-path: '../fonts/slick-carousel/slick/fonts/' !default;
$slick-loader-path: '../img/vendor/slick-carousel/slick/' !default;

$pswp__assets-path: '../img/vendor/photoswipe/dist/default-skin/' !default;
