//custom variables go here

$font-size-html-base: 17px;
$font-family-sans-serif: 'Kanit', sans-serif;

$brand-primary: #133579;
$brand-secondary: #ffdd30;

$navbar-padding-x: 0;
$navbar-padding-y: 0;

$body-color: #333;

$font-weight-light: 100;
$font-weight-normal: 300;
$font-weight-bold: 700;

$navbar-toggler-padding-y: .5rem;
$navbar-toggler-font-size: 25px;

$navbar-light-color: #333;
$navbar-light-hover-color: #000;
$navbar-light-toggler-border-color: transparent;

$enable-rounded: false;

$input-border-color: $body-color;
$input-focus-border-color: $brand-secondary;
